import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state() {
    return {
      tariffs: null,
      tariffsJet: null,
      tariffsTurbo: null,
      additionalServices: null,
    }
  },
  mutations: {
    setTariffs(state, tariffs) {
      state.tariffs = tariffs
    },
    setTariffsJet(state, tariffs) {
      state.tariffsJet = tariffs
    },
    setTariffsTurbo(state, tariffs) {
      state.tariffsTurbo = tariffs
    },
    setAdditionalServices(state, additionalServices) {
      state.additionalServices = additionalServices
    }
  },
  actions: {
    async fetchTariffs({commit, dispatch, rootGetters}, id) {
      try {
        const {data} = await axios.get(`/api/tariffs/${id}`)

        if (data?.[0]?.mainTariff?.o_Result === '1') {
          commit('setTariffs', data?.[0]?.mainTariff?.row || null)
        } else {
          throw new Error(data?.[0]?.mainTariff?.o_Out_Text || 'unknown error');
        }

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchTariffsTurbo({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/loyalty/jet-buttons/${id}`)

        if (data?.[0]?.o_Result === '1') {
          commit('setTariffsTurbo', data?.[0]?.row || null)
        } else {
          throw new Error(data?.[0]?.o_Out_Text || 'unknown error');
        }


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchTariffsJet({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/loyalty/jet-tariffs/${id}`)

        if (data?.[0]?.o_Result === '1') {
          commit('setTariffsJet', data?.[0]?.row || null)
        } else {
          throw new Error(data?.[0]?.o_Out_Text || 'unknown error');
        }


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async fetchAdditionalServices({commit, dispatch}, id) {
      try {
        const {data} = await axios.get(`/api/get-active-packages/${id}`)
        if (data?.o_Result === '1') {
          commit('setAdditionalServices', data?.row || null)
        } else {
          throw new Error(data?.o_Out_Text || 'unknown error');
        }

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async changeTariff({dispatch}, {inetServiceId, newTariffId}) {
      try {
        const {data} = await axios.post(`/api/change-tariff/${inetServiceId}`, {
          newTariffId
        })

        dispatch('setMessage', {
          value: data?.response?.[0]?.o_Out_Text || 'unkown',
          type: data?.response?.[0]?.o_Result === '1' ? 'success' : 'error'
        }, {root: true})

        return {status: data?.response?.[0]?.o_Result}

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async buyAdditionalService({dispatch}, {inetServiceId, serviceId}) {
      try {
        const {data} = await axios.post(`/api/buy-ott-packages/${inetServiceId}/${serviceId}`)
        dispatch('setMessage', {
          value: data?.o_Out_Text || 'unkown',
          type: data?.o_Result === '1' ? 'success' : 'error'
        }, {root: true})

        return {status: data?.o_Result}

      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    },
    async enableTurbo({dispatch}, {inetServiceId, newTariffId}) {
      try {
        const {data} = await axios.post(`/api/loyalty/turbo-button/${inetServiceId}`, {
          packageType: newTariffId
        })

        dispatch('setMessage', {
          value: data?.response?.o_Out_Text || 'unkown',
          type: data?.response?.o_Result === '1' ? 'success' : 'error'
        }, {root: true})


      } catch (e) {
        dispatch('setMessage', {
          value: e.message,
          type: 'error'
        }, {root: true})
      }
    }
  },
  getters: {
    tariffs(state) {
      return state.tariffs
    },
    tariffsJet(state) {
      return state.tariffsJet
    },
    tariffsTurbo(state) {
      return state.tariffsTurbo
    },
    additionalServices(state) {
      return state.additionalServices
    }
  }
}
